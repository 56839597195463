<script setup>
import { WdsButtonLink } from '@wds/components';
import { BLOG_DATA } from '~/components/homepage/HwTipsAndAdventures/constants.js';

const { locale } = useI18n();

const { $tracking } = useNuxtApp();

// ### COMPUTED
const cards = computed(() => {
  return BLOG_DATA[locale?.value];
});

// ### TRACKING
function trackReadBlogCarouselLinkClicks() {
  $tracking.onBlogItemInteraction({ parameter1: null });
}
</script>

<template>
  <section
    v-if="cards"
    class="tips-and-adventures-section"
  >
    <div class="header">
      <span class="title">{{ $t('t_TRAVELTIPS') }}</span>
      <p class="sub-title">
        {{ $t('t_DONTMISS') }}
      </p>
    </div>
    <div class="cards-carousel">
      <HomepageHwTipsAndAdventuresCard
        v-for="card in cards"
        :key="card.link"
        :img="card.image"
        :link="card.link"
        :target="card.target"
        :text="card.text"
      />
    </div>

    <div class="carousel-footer">
      <WdsButtonLink
        :href="$t('t_URLBLOG')"
        :text="$t('t_READOURBLOG')"
        :title="$t('t_READOURBLOG')"
        icon-end="chevron-right"
        rel="noopener"
        type="button"
        @click="trackReadBlogCarouselLinkClicks"
      />
    </div>
  </section>
</template>

<style lang="scss" scoped>
.tips-and-adventures-section {
  max-width: wds-rem(1600px);
  margin: 0 auto;
  padding: $wds-spacing-l 0 wds-rem(40px) 0;

  & .header {
    color: $wds-color-ink-darker;
    padding-left: $wds-spacing-m;
    padding-right: $wds-spacing-m;

    & .title {
      @include title-3-bld;
    }

    & .sub-title {
      @include body-1-reg;
    }
  }

  & .cards-carousel {
    display: flex;
    flex-direction: row;
    gap: $wds-spacing-m;
    overflow: auto;
    padding: $wds-spacing-m $wds-spacing-m;

    & a:last-child {
      padding-right: $wds-spacing-m;
    }
  }
}

@include tablet {
  .tips-and-adventures-section {
    & .header {
      @include title-2-bld;
    }
  }
}

@include desktop {
  .tips-and-adventures-section {
    padding-right: $wds-spacing-l;
    padding-left: $wds-spacing-l;

    & .cards-carousel {
      padding-top: wds-rem(40px);
    }
  }
}

.carousel-footer {
  display: inline-flex;
  justify-content: flex-end;
  width: 100%;

  :deep(.btn-content) {
    cursor: pointer;
  }
}
</style>
